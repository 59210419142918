import * as React from "react"
import { Box, Center } from "@chakra-ui/layout"
import { Image } from "@chakra-ui/image"

import ziptiLogo from "../images/ziptiLogo.svg"
import ZiptiText from "../components/shared/zipti_text"
import { useEffect } from "react"
import { useContext } from "react"
import { UserContext } from "../components/user_wrapper"

const SignoutPage: React.FC = props => {
  const user = useContext(UserContext)
  useEffect(() => {
    user.signout()
  }, [])
  return (
    <Box>
      <Center>
        <Image mt="20vh" w="30vh" h="30vh" src={ziptiLogo} />
      </Center>
      <Center>
        <ZiptiText mt="10vh" fontWeight="bold">
          Signing you out!
        </ZiptiText>
      </Center>
    </Box>
  )
}

export default SignoutPage
